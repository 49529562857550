import React from 'react'
import '../../App.css'
import Footer from '../Footer'
import AboutBody from '../AboutBody'

function About() {

    return (
        <>
            <AboutBody />
            <Footer />

        </>
    )
}

export default About