import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { FSButton } from './FSButton';
import LoginModal from './LoginModal';
import MyVerticallyCenteredModal from './MyVerticallyCenteredModal';
import './Navbar.css';

function Navbar() {

    const [modalShow, setModalShow] = React.useState(false);
    const [loginModalShow, setLoginModalShow] = React.useState(false);

    const [button, setButton] = useState(true);
    const [navLinks, setNavLinks] = useState(true);
    const [tagline, setTagline] = useState(true);

    const showButton = () => {
        if (window.innerWidth <= 800) {
            setButton(false);
        } else {
            setButton(true);
        }
    };

    const showNavLinks = () => {
        if (window.innerWidth <= 600) {
            setNavLinks(false);
        } else {
            setNavLinks(true);
        }
    };

    const showTagline = () => {
        if (window.innerWidth <= 1016) {
            setTagline(false);
        } else {
            setTagline(true);
        }
    }

    useEffect(() => {
        showButton();
        showNavLinks();
        showTagline();
    }, []);

    window.addEventListener('resize', showButton);
    window.addEventListener('resize', showNavLinks);
    window.addEventListener('resize', showTagline);

    return (
        <>
            <nav className='navbar-layout'>
                <div className='navbar-container'>
                    <div className="navbar-logo-container">
                        <div className="logo">
                            <Link className='navbar-logo' to='/'>
                                    <img
                                        className='navbar_logo_img'
                                        alt='fiStone Logo'
                                        src='images/Fistone Logo - flat horizontal text transparent.png'
                                    />
                            </Link>
                        </div>
                        {tagline && (
                            <div className="bullet-point">
                                •
                            </div>
                        )}
                        {tagline && (
                            <div className="navbar-tagline">
                                The path to reaching your Financial Milestones
                            </div>
                        )}
                    </div>
                    <div className='nav-right-side'>
                        {navLinks && (
                            <ul className={'nav-menu'}>
                                <li className='nav-item'>
                                    <Link className='nav-links' to='/about'>
                                        About
                                    </Link>
                                </li>
                            </ul>
                        )}
                        <div className="parent">
                            {button && (<FSButton buttonStyle='btn--outline' onClick={() => setLoginModalShow(true)}>Log In</FSButton>)}
                            <FSButton buttonStyle='btn--primary' onClick={() => setModalShow(true)}>Sign Up</FSButton>
                        </div>

                    </div>
                </div>
            </nav>
            <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
            <LoginModal
                show={loginModalShow}
                onHide={() => setLoginModalShow(false)}
            />
        </>
    );
}

export default Navbar