import React from 'react';
import { FSButton } from './FSButton';
import MyVerticallyCenteredModal from './MyVerticallyCenteredModal';

function CardItem(props) {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <li className='cards_item'>
        <div className='cards_item_link'>
          <figure>
            <img
              className='cards_item_img'
              alt={props.altText}
              src={props.src}
            />
          </figure>
          <div className='cards_item_title'>
            <h5 className='cards_item_title_text'>{props.label}</h5>
          </div>
          <div className='cards_item_info'>
            <h5 className='cards_item_info_text'>{props.text}</h5>
          </div>
          <div className='cards_button_container'>
           
            <FSButton
              className='cards_learn_more_button'
              onClick={() => setModalShow(true)}
              buttonLocation='btn--card'>
              Learn More
            </FSButton>
        
          </div>
        </div>
      </li>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default CardItem;