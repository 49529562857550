import React, { useEffect, useState } from 'react';
import '../App.css';
import './Main.css';
import { FSButton } from './FSButton';
import MyVerticallyCenteredModal from './MyVerticallyCenteredModal';

function Main() {
    const [modalShow, setModalShow] = React.useState(false);
    const [tagline, setTagline] = useState(false);

    const showTagline = () => {
        if (window.innerWidth <= 1016) {
            setTagline(true);
        } else {
            setTagline(false);
        }
    }

    useEffect(() => {
        showTagline();
    }, []);

    window.addEventListener('resize', showTagline);

    return (
        <div>
            <div className='main-container'>
                <h1>We believe that access to sophisticated financial advice shouldn't just be for the wealthy.</h1>
                <p>Get financial advice to tackle life's big events with professionally curated content, do-it-yourself tools, and coaching from a financial advisor.</p>
                <div className='main-btn'>
                    <FSButton buttonStyle="btn--primary" onClick={() => setModalShow(true)}>
                        Learn More
                    </FSButton>
                </div>
            </div>
            {tagline && (
                <div className='tagline'>
                    The path to reaching your Financial Milestones
                </div>
            )}
            <div className='main_title'>
                Learn, Plan, Review
            </div>

            <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </div>
    )
}

export default Main