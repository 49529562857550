import React from 'react'
import '../../App.css'
import Footer from '../Footer'
import PPBody from '../PPBody'

function PrivacyPolicy() {
    
    return (
        <>
            <PPBody/>
            <Footer />
           
        </>
    )
}

export default PrivacyPolicy