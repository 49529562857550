import React from "react";
import { Link } from 'react-router-dom';
import './FSButton.css';

const STYLES = ['btn--primary', 'btn--outline'];

const SIZES = ['btn--medium', 'btn--large'];

const LOCATION = ['btn--navbar', 'btn--footer', 'btn--card'];

export const FSButton = ({
  children,
  type,
  onClick,
  buttonStyle,
  buttonSize,
  buttonLocation
}) => {
  const checkButtonStyle = STYLES.includes(buttonStyle) ? buttonStyle : STYLES[0];

  const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0]

  const checkButtonLocation = LOCATION.includes(buttonLocation) ? buttonLocation : LOCATION[0];

  return (
    <Link className='btn-mobile'>
      <button
        className={`btn-layout ${checkButtonStyle} ${checkButtonSize} ${checkButtonLocation}`}
        onClick={onClick}
        type={type}
      >
        {children}
      </button>
    </Link>
  )
}