import React from 'react'
import '../App.css'
import './AboutBody.css';

function AboutBody() {

    return (
        <div>
            <div className='privacy-policy'>
                <h1>Our Mission</h1>
                <p>We believe that access to sophisticated financial advice shouldn't just be for the wealthy and that's why we created fiStone.</p>
                <p>Get financial advice to tackle life's big events with professionally curated content, powerful AI-powered do-it-yourself tools, and validation and coaching from a <em>real</em> human financial advisor, not robots or algorithms.</p>
                <p>Start on the path to reaching your Financial Milestones with fiStone.</p>
            </div>
        </div>
    )
}

export default AboutBody