import React from 'react'
import '../App.css'
import './TeamBody.css';
import { COLORS } from '../Colors.js'

function TeamBody() {

    return (
        <div className='team-container'>
            <h1>
                Meet Our Team
            </h1>
            <div className='bio-container'>
                <div className='image-title-container'>
                    <figure>
                        <img
                            className='bio-image'
                            alt='David Headshot'
                            src='images/david.png'
                        />
                    </figure>
                    <div className='title-container'>
                        <h2>
                            David D. Nguyen
                        </h2>
                        <h3>
                            Co-Founder / CEO
                        </h3>
                        <h4>
                            BA, University of Pennsylvania
                        </h4>
                        <h4>
                            MBA, Cornell University
                        </h4>
                    </div>
                </div>
                <div className='summary-container'>
                    <p>
                        An experienced leader, David, has over a decade of experience in the financial services Industry with expertise in wealth management. Prior to FiStone, David was a management consultant and subject matter expert at EY Consulting's Wealth & Asset Management practice. Prior to EY, David was a people leader at Charles Schwab & Co., where he was responsible for leading teams of client-facing financial advisors for the Washington, D.C. metro area. Prior to that, David served as a Vice President at a Philadelphia-based registered investment advisor (RIA) that served clients throughout the Northeast and Midwest regions of the US. David started his career at Voya Financial (formerly known as ING), where he placed in the top 2% of all advisors nationally, earning him the circle of excellence award. David holds a CFP® certification, and previously held FINRA Series 7, 9, 10, 24, and 66 registrations. David holds both a Bachelor of Arts from the University of Pennsylvania and a Master of Business Administration with Distinction from Cornell University.
                    </p>
                </div>
            </div>
            <div className='bio-container'>
                <div>
                    <figure>
                        <img
                            className='bio-image'
                            alt='Steven Headshot'
                            src='images/steven.jpeg'
                        />
                    </figure>
                    <div className='title-container'>
                        <h2>
                            Steven Beyers
                        </h2>
                        <h3>
                            Co-Founder / CTO
                        </h3>
                        <h4>
                            BS, Indiana University of Pennsylvania
                        </h4>
                    </div>
                </div>
                <div className='summary-container'>
                    <p>
                        Having worked as mobile developer since the dawn of the iOS apps, Steven brings nearly two decades of experience in mobile development, with expertise across the entire development life cycle and understanding of various technology stacks. An accomplished engineering leader, Steven has an extensive track record of assembling high-performing engineering teams to successfully drive delivery of exceptional products. Steven has worked with large institutions spanning a variety of industries including financial services, entertainment, and retail. Prior to FiStone, Steven held various positions at Scoir, a venture-backed startup, CapTech Ventures, and Lockheed Martin. Steven holds a Bachelor of Science in Computer Science from the Indiana University of Pennsylvania.
                    </p>
                </div>
            </div>
            <div className='bio-container'>
                <div>
                    <figure>
                        <img
                            className='bio-image'
                            alt='Amanda Headshot'
                            src='images/amanda.jpeg'
                        />
                    </figure>
                    <div className='title-container'>
                        <h2>
                            Amanda Beyers
                        </h2>
                        <h3>
                            Co-Founder / Principal Software Engineer
                        </h3>
                        <h4>
                            BSE, University of Pennsylvania
                        </h4>
                    </div>
                </div>
                <div className='summary-container'>
                    <p>
                        Amanda is an experienced software engineer with expertise in design and development of mobile applications for both iOS and Android platforms. She brings a broad range of experience having developed apps for the travel and healthcare industries as well as large research institutions. Previously, Amanda was a technical lead at The Boeing Company, developing digital tools to aid in the detailed analysis of aircraft components. Amanda holds a Bachelor of Science in Engineering from the University of Pennsylvania.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default TeamBody