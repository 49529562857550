import React from 'react';
import './App.css';
import Home from './components/pages/Home';
import About from './components/pages/About';
import PrivacyPolicy from './components/pages/PrivacyPolicy';
import Team from './components/pages/Team';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ScrollToTop from "./ScrollToTop";

function App() {
  return (
  <>
  <Router>
<ScrollToTop/>
    <Navbar classname ="navbar"/>
    <Routes>
      <Route exact path='/' element= {<Home/>} />
      <Route exact path='/about' element= {<About/>} />
      <Route exact path='/privacypolicy' element= {<PrivacyPolicy/>} />
      <Route exact path='/team' element= {<Team/>} />
    </Routes>
  </Router>
  </>
  );
}

export default App;
