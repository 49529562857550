import { doc, setDoc } from 'firebase/firestore';
import React from 'react';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { db } from '../firebase-config';
import { FSButton } from './FSButton';
import './MyVerticallyCenteredModal.css';


function MyVerticallyCenteredModal(props) {
    const [email, setEmail] = React.useState("");
    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [age, setAge] = React.useState("");
    const [profession, setProfession] = React.useState("");
    const [maritalStatus, setMaritalStatus] = React.useState("");
    const [houseIncome, setHouseIncome] = React.useState("");
    const [firstFormShow, setFirstFormShow] = React.useState(true);
    const [secondFormShow, setSecondFormShow] = React.useState(false);
    const [thirdFormShow, setThirdFormShow] = React.useState(false);
    const [allGoals, setAllGoals] = React.useState({ student: false, house: false, wedding: false, college: false, retirement: false, taxes: false, invest: false })
    const [allAdvice, setAllAdvice] = React.useState({ online: false, friends: false, books: false, advisor: false, app: false, retirement: false })
    const [firstNameLabel, setFirstNameLabel] = React.useState("");
    const [lastNameLabel, setLastNameLabel] = React.useState("");
    const [emailLabel, setEmailLabel] = React.useState("");
    const [welcomeMessage, setWelcomeMessage] = React.useState("Thanks for your interest! New users are currently by invitation only. Sign up for the invite list below!");
    const [modalTitle, setModalTitle] = React.useState("");
    const [ageLabel, setAgeLabel] = React.useState("");

    const submitClick = () => {
        if (firstFormShow) {
            onFirstSubmit();
        } else {
            onSecondSubmit();
        }
    }

    const onFirstSubmit = () => {
        var filled = true
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

        if (firstName === "") {
            setFirstNameLabel(" *Required")
            filled = false
        } else {
            setFirstNameLabel("")
        }
        if (lastName === "") {
            setLastNameLabel(" *Required")
            filled = false
        } else {
            setLastNameLabel("")
        }
        if (!emailRegex.test(email.toLowerCase())) {
            setEmailLabel(" *Enter a valid email address")
            filled = false
        } else {
            setEmailLabel("")
        }

        if (filled) {
            submitFirstForm();
            setWelcomeMessage("We'll keep you updated! For now, let us know a little more about yourself and your financial goals...")
            setModalTitle("You're in!")
            setFirstFormShow(false);
            setSecondFormShow(true);
        }
    }

    const onSecondSubmit = () => {
        var filled = true
        if (age < 18) {
            setAgeLabel("*You must be at least 18 years of age.")
            filled = false
        } else {
            setAgeLabel("")
        }

        if (filled) {
            var goalsInput = createGoalsInput();
            var adviceInput = createAdviceInput();
            setThirdFormShow(true);
            setSecondFormShow(false);
            submitSecondForm(goalsInput, adviceInput);
            setWelcomeMessage("");
            clearValues()
        }
    }

    const onThirdClose = () => {
        setFirstFormShow(true);
        setThirdFormShow(false);
    }

    const createGoalsInput = () => {
        var filteredGoals = Object.keys(allGoals).reduce(function (filteredGoals, key) {
            if (allGoals[key] === true) filteredGoals[key] = allGoals[key];
            return filteredGoals;
        }, {});
        console.log(filteredGoals);

        var selectedGoals = [];

        for (var key in filteredGoals) {
            selectedGoals.push(key)
        };

        return selectedGoals
    }

    const createAdviceInput = () => {
        var filteredAdvice = Object.keys(allAdvice).reduce(function (filteredAdvice, key) {
            if (allAdvice[key] === true) filteredAdvice[key] = allAdvice[key];
            return filteredAdvice;
        }, {});
        console.log(filteredAdvice);

        var selectedAdvice = [];

        for (var key in filteredAdvice) {
            selectedAdvice.push(key)
        };

        return selectedAdvice
    }

    const submitFirstForm = async () => {
        await setDoc(doc(db, "UserInfo", email), {
            firstName: firstName,
            lastName: lastName,
            email: email
        })
    }

    const submitSecondForm = async (goals, advice) => {
        await setDoc(doc(db, "UserInfo", email), {
            goals: goals,
            age: age,
            advice: advice,
            profession: profession,
            maritalStatus: maritalStatus,
            houseIncome: houseIncome
        }, { merge: true })
    }

    const handleGoalCheckbox = event => {
        let myGoals = allGoals;
        myGoals[event.target.value] = event.target.checked;
        setAllGoals(myGoals);
        console.log(allGoals);
    }

    const handleAdvCheckbox = event => {
        let myAdvice = allAdvice;
        myAdvice[event.target.value] = event.target.checked;
        setAllAdvice(myAdvice);
        console.log(allAdvice);
    }

    const handleClose = () => {
        if (thirdFormShow) {
            onThirdClose()
        }
        clearValues()
        setFirstFormShow(true)
        setSecondFormShow(false)
        setWelcomeMessage("Thanks for your interest! New users are currently by invitation only. Sign up for the invite list below!")
        setModalTitle("")
    }

    const clearValues = () => {
        setEmail("")
        setFirstName("")
        setLastName("")
        setAge("")
        setProfession("")
        setMaritalStatus("")
        setHouseIncome("")
        setAgeLabel("")
        setFirstNameLabel("")
        setLastNameLabel("")
        setEmailLabel("")
    }

    return (
        <>
            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >

                <Modal.Header className='fsmodal-header'>
                    <div className='header-text'>
                        <div className='fsmodal-title'>
                            {modalTitle}
                        </div>
                        <div className='modal-welcome-message'>
                            {welcomeMessage}
                        </div>
                    </div>
                </Modal.Header>
                {firstFormShow && (
                    <Modal.Body >
                        <Form>
                            <Form.Group className="mb-3" controlId="formBasicFirstName">
                                <Form.Label>First Name</Form.Label>
                                <Form.Label className='required-text'>{firstNameLabel}</Form.Label>
                                <Form.Control type="text" placeholder="First Name" onChange={(event) => {
                                    setFirstName(event.target.value);
                                }} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicLastName">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Label className='required-text'>{lastNameLabel}</Form.Label>
                                <Form.Control type="text" placeholder="Last Name" onChange={(event) => {
                                    setLastName(event.target.value);
                                }} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Email Address</Form.Label>
                                <Form.Label className='required-text'>{emailLabel}</Form.Label>
                                <Form.Control type="email" placeholder="Enter email" onChange={(event) => {
                                    setEmail(event.target.value);
                                }} />
                                <Form.Text className='fin-details'>
                                    We'll never share your email with anyone else.
                                </Form.Text>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                )}
                {secondFormShow && (
                    <Modal.Body >
                        <Form>
                            <Form.Group className='fin-details'>
                                <Form.Label>Financial Concerns / Goals (select all that apply)</Form.Label>

                                <Form.Check
                                    type={'checkbox'}
                                    id={`student`}
                                    label={`Paying off student loans`}
                                    value={'student'}
                                    onChange={(event) => {
                                        handleGoalCheckbox(event)
                                    }}
                                />

                                <Form.Check
                                    type={'checkbox'}
                                    id={`house`}
                                    label={`Buying a house`}
                                    value={'house'}
                                    onChange={(event) => {
                                        handleGoalCheckbox(event)
                                    }}
                                />
                                <Form.Check
                                    type={'checkbox'}
                                    id={`wedding`}
                                    label={`Paying for a wedding`}
                                    value={'wedding'}
                                    onChange={(event) => {
                                        handleGoalCheckbox(event)
                                    }}
                                />
                                <Form.Check
                                    type={'checkbox'}
                                    id={`college`}
                                    label={`Saving for college`}
                                    value={'college'}
                                    onChange={(event) => {
                                        handleGoalCheckbox(event)
                                    }}
                                />
                                <Form.Check
                                    type={'checkbox'}
                                    id={`retirement`}
                                    label={`Saving for retirement`}
                                    value={'retirement'}
                                    onChange={(event) => {
                                        handleGoalCheckbox(event)
                                    }}
                                />
                                <Form.Check
                                    type={'checkbox'}
                                    id={`taxes`}
                                    label={`Reducing taxes`}
                                    value={'taxes'}
                                    onChange={(event) => {
                                        handleGoalCheckbox(event)
                                    }}
                                />
                                <Form.Check
                                    type={'checkbox'}
                                    id={`invest`}
                                    label={`Selecting the right investments`}
                                    value={'invest'}
                                    onChange={(event) => {
                                        handleGoalCheckbox(event)
                                    }}
                                />
                            </Form.Group>
                            <Form.Group className='fin-details'>
                                <Form.Label>Where do you get your financial advice now, if any? (select all that apply)</Form.Label>

                                <Form.Check
                                    type={'checkbox'}
                                    id={`online`}
                                    label={`I look online (e.g. Google, Reddit, etc.)`}
                                    value={'online'}
                                    onChange={(event) => {
                                        handleAdvCheckbox(event)
                                    }}
                                />

                                <Form.Check
                                    type={'checkbox'}
                                    id={`friends`}
                                    label={`I ask friends and family`}
                                    value={'friends'}
                                    onChange={(event) => {
                                        handleAdvCheckbox(event)
                                    }}
                                />
                                <Form.Check
                                    type={'checkbox'}
                                    id={`books`}
                                    label={`I read books`}
                                    value={'books'}
                                    onChange={(event) => {
                                        handleAdvCheckbox(event)
                                    }}
                                />
                                <Form.Check
                                    type={'checkbox'}
                                    id={`advisor`}
                                    label={`I have a financial advisor`}
                                    value={'advisor'}
                                    onChange={(event) => {
                                        handleAdvCheckbox(event)
                                    }}
                                />
                                <Form.Check
                                    type={'checkbox'}
                                    id={`app`}
                                    label={`I use a financial app (e.g. Betterment)`}
                                    value={'app'}
                                    onChange={(event) => {
                                        handleAdvCheckbox(event)
                                    }}
                                />
                                <Form.Check
                                    type={'checkbox'}
                                    id={`retirement`}
                                    label={`I have a 401(k)/ retirement plan through my job`}
                                    value={'retirement'}
                                    onChange={(event) => {
                                        handleAdvCheckbox(event)
                                    }}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicFirstName">
                                <Form.Label>Age</Form.Label>
                                <Form.Label className='required-text'>{ageLabel}</Form.Label>
                                <Form.Control type="number" min='18' max='120' placeholder="Age" onChange={(event) => {
                                    setAge(event.target.value);
                                }} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicLastName">
                                <Form.Label>Profession</Form.Label>
                                <Form.Control type="text" placeholder="Profession" onChange={(event) => {
                                    setProfession(event.target.value);
                                }} />
                            </Form.Group>
                            <Form.Group className='fin-details'>
                                <Form.Label>Marital Status</Form.Label>
                                {(
                                    <div key={'inline-radio'} className="marital-status">
                                        <Form.Check
                                            type={'radio'}
                                            name="maritalChoices"
                                            id={`single`}
                                            label={`Single`}
                                            onChange={(event) => {
                                                setMaritalStatus(`Single`);
                                            }}
                                        />
                                        <Form.Check
                                            type={'radio'}
                                            name="maritalChoices"
                                            id={`married`}
                                            label={`Married`}
                                            onChange={(event) => {
                                                setMaritalStatus(`Married`);
                                            }}
                                        />
                                        <Form.Check
                                            type={'radio'}
                                            name="maritalChoices"
                                            id={`household`}
                                            label={`Head of household`}
                                            onChange={(event) => {
                                                setMaritalStatus(`Head of household`);
                                            }}
                                        />

                                    </div>
                                )}

                            </Form.Group>
                            <Form.Group className='fin-details'>
                                <Form.Label>Annual household income</Form.Label>
                                <Form.Check
                                    type={'radio'}
                                    name="incomeChoices"
                                    id={`0-75`}
                                    label={`$0-75K`}
                                    onChange={(event) => {
                                        setHouseIncome(`$0-75K`);
                                    }}
                                />
                                <Form.Check
                                    type={'radio'}
                                    name="incomeChoices"
                                    id={`75-150`}
                                    label={`$75-150K`}
                                    onChange={(event) => {
                                        setHouseIncome(`$75-150K`);
                                    }}
                                />
                                <Form.Check
                                    type={'radio'}
                                    name="incomeChoices"
                                    id={`150-250`}
                                    label={`$150-250K`}
                                    onChange={(event) => {
                                        setHouseIncome(`$150-250K`);
                                    }}
                                />
                                <Form.Check
                                    type={'radio'}
                                    name="incomeChoices"
                                    id={`250-500`}
                                    label={`$250-500K`}
                                    onChange={(event) => {
                                        setHouseIncome(`$250-500K`);
                                    }}
                                />
                                <Form.Check
                                    type={'radio'}
                                    name="incomeChoices"
                                    id={`500+`}
                                    label={`$500K+`}
                                    onChange={(event) => {
                                        setHouseIncome(`$500K+`);
                                    }}
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                )}

                {thirdFormShow && (
                    <Modal.Body >
                        <div className='submit-confirm'>
                            Thanks for your feedback! We'll be in touch!
                        </div>
                    </Modal.Body>
                )}
                <Modal.Footer>
                    <div className='footer-buttons'>
                        <FSButton buttonStyle='btn--outline' onClick={props.onHide}>
                            Close
                        </FSButton>
                        {(!thirdFormShow &&
                            <FSButton buttonStyle='btn--primary' onClick={submitClick}>
                                Submit
                            </FSButton>
                        )}

                    </div>
                    {(!thirdFormShow &&
                        <div className='age-certify'>
                            *By clicking Submit you certify that you are at least 18 years old.*
                        </div>
                    )}

                </Modal.Footer>
            </Modal>
        </>
    );
}

export default MyVerticallyCenteredModal;