import React from 'react'
import '../App.css'
import './PPBody.css';

function PrivacyPolicyBody() {

    return (
        <div>
            <div className='privacy-policy'>
                <h1>FiStone Privacy Policy</h1>
                <h2>Your Privacy Matters</h2>
                <p>FiStone’s number one priority is your trust. Your privacy is essential to earning and keeping that trust. This tenet drives all of the decisions we make, as well as how we gather, use and store any information we acquire from you.</p>
                <p>We created this Privacy Policy to be as clear and direct as possible about how we gather and use your non-public personal information and other types of personal information (“Personal Information”) and to assist you in exercising your privacy rights.</p>
                <h2>Information Collection</h2>
                <p>The categories of information we collect from Users differs from what we collect from those who qualify as Clients. Examples of instances when we collect Personal Information include:</p>
                <p>when you answer questions on the Site or in the App to determine what kind of portfolio we might recommend if you were to become a Client,{"\n"}
                    when you register to open an account either as a User or a Client,{"\n"}
                    when you contact our client service organization with questions, or{"\n"}
                    when you agree to enter one of our Client Agreements.</p>
                <h2>Information We Collect from Users and Clients</h2>
                <p>We collect Personal Information from Users and Clients, including but not limited to:</p>
                <p>name, e-mail address, telephone number, zip code, Internet Protocol address, birth date;
                    investable assets, income information, and other financial planning information about your household; and
                    account numbers, routing numbers, and login credentials for the financial accounts you choose to link to our Services, any challenge and/or security questions associated with those linked accounts, and any information contained in those linked accounts.</p>
                <h2>Additional Information We Collect from Clients Only</h2>
                <p>In addition to the information described above, Clients provide us additional Personal Information, including but not limited to:</p>
                <p>your full legal name, contact information, birth date, Social Security Number, citizenship, and marital status;{"\n"}
                    the full legal name, birth date, and Social Security Number of any beneficiaries that Clients choose to list;{"\n"}
                    for purposes of identity verification, government-issued identification documents and self-portrait photographs (“selfie”); and
                    investment objectives, approximate net worth, tax information, and other information required by federal and industry laws and regulations, such as employment information and stock restrictions</p>
                <p>You are not required to provide these items to us to become a User, but Users who choose not to provide these items to us will not be able to become Clients.</p>
                <h2>Information Regarding Children</h2>
                <p>Due to the nature of our business, our Services are not available to minors. Except for beneficiary information as described above, FiStone does not knowingly solicit Personal Information directly from or about persons under the age of 18.</p>
                <p>If you are under the age of 18, please do not submit any Personal Information to FiStone.</p>
                <h2>Other Ways We Collect Information</h2>
                <p>Other means by which we collect Personal Information include the following:</p>
                <p><b>Automatic Data Collection.</b> When you use our Services, we may collect your Internet protocol (“IP”) address; cookie identifiers; mobile carrier, mobile advertising and other unique identifiers; details about your browser, operating system or device; location information; Internet service provider information; pages that you visit before, during and after using the Services; information about the links you click; and other information about how you use the Services. Information we collect may be associated with accounts and other devices.</p>
                <p><b>Anonymized or Aggregated Information.</b> FiStone’s Site and App record certain anonymized or aggregated information about your use or evaluation of our Services. Anonymized or aggregated information is used for a variety of purposes, including the measurement of Users’ and Clients’ interest in and use of various portions or features of the Site and App. Anonymized or aggregated information is not Personal Information, and we may use such information in a number of ways, including for internal analysis and research. We may share this information with third parties for our purposes in an anonymized or aggregated form that is designed to prevent anyone from identifying you.</p>
                <p><b>Cookies and Pixels.</b> Similar to other consumer internet services, FiStone uses cookies, a small piece of computer code that enables our Web servers to identify Users and Clients, each time an individual initiates a session on our Site. A cookie is set in order to identify you and tailor the Site to you. Cookies do not store any of the Personal Information that you provided to us; they are simply identifiers. You may delete cookie files from your hard drive at any time through your browser settings. However, cookies may be necessary to provide access to much of the content and many of the features of the Site.</p>
                <p><b>Pixel Tags.</b> Along with cookies, we may use “pixel tags,” also known as “web beacons,” which are small graphic files that allow us to monitor the use of our Site. A pixel tag can collect information such as the IP address of the computer that downloaded the page on which the tag appears; the URL of the page on which the pixel tag appears; the time the page containing the pixel tag was viewed; the type of browser that fetched the pixel tag; and the identification number of any cookie on the computer previously placed by that server.</p>
                <p><b>Site and App Activity.</b> FiStone may also use third-party tracking technology, such as Google Analytics, to record your activity on our Site and App.</p>
                <p><b>“Do Not Track” Technology.</b> We do not collect Personal Information about your online activities over time and across different websites or online services. Therefore, our Site does not respond to Do Not Track (“DNT”) signals. We do not knowingly authorize third parties to collect Personal Information about your online activities over time and across different websites or online services.</p>
                <p><b>Surveys.</b> We may contact you to participate in surveys. If you decide to participate, you may be asked to provide certain information which may include Personal Information.</p>
            </div>
        </div>
    )
}

export default PrivacyPolicyBody