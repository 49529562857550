import React from 'react'
import '../../App.css'
import Footer from '../Footer'
import TeamBody from '../TeamBody'

function Team() {
    
    return (
        <>
        <TeamBody/>
            <Footer />
           
        </>
    )
}

export default Team