import React from 'react';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { FSButton } from './FSButton';
import './LoginModal.css';

function LoginModal(props) {
    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header >
                    <div className="login-modal-title">
                        Log In
                    </div>
                </Modal.Header>
                <Modal.Body show={false}>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" placeholder="Enter email" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="text" placeholder="Enter password" />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <FSButton buttonStyle='btn--outline' onClick={props.onHide}>
                        Close
                    </FSButton>
                    <FSButton buttonStyle='btn--primary'>
                        Submit
                    </FSButton>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default LoginModal;