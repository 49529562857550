import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function Cards() {
  return (
    <div className='cards'>
      <div className='cards_container'>
        <div className='cards_wrapper'>
          <ul className='cards_items'>
            <CardItem
              src='images/open-book.png'
              text='No more endlessly searching the web. Learn how to tackle big life events with financial education resources created and vetted by financial professionals.'
              label='Curated Content Library'
              altText='Open Book'
            />
            <CardItem
              src='images/tool-box.png'
              text='Professional-grade financial planning tools made easy with a guided walkthrough so you can design the future of your dreams.'
              label='AI-Powered DIY Planning Tools'
              altText='Toolbox'
            />
             <CardItem
              src='images/advisor.png'
              text="Completed your dream financial plan? Tweak and validate it with an actual financial advisor so you know you're on track."
              label='Human Financial Advisor'
              altText='Human With Headset'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;