import React from 'react'
import '../../App.css'
import Main from '../Main'
import Cards from '../Cards'
import Footer from '../Footer'

function Home() {
    
    return (
        <>
     
            <Main/>
            <Cards/>
            <Footer />
           
        </>
    )
}

export default Home