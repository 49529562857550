import React from 'react';
import './Footer.css';
import { FSButton } from './FSButton';
import { Link } from 'react-router-dom';
import MyVerticallyCenteredModal from './MyVerticallyCenteredModal';

function Footer() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <div className='footer-container'>
      <div class='footer-links'>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>Company</h2>
            <Link to='/about'>About</Link>
            <Link to='/team'>Team</Link>
          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>Legal</h2>
            <Link to='/privacypolicy'>Privacy Policy</Link>
          </div>
        </div>
        <section className='footer-subscription'>
          <p className='footer-subscription-heading'>
            Subscribe to our newsletter
          </p>
          <p className='footer-subscription-text'>
            Get the latest product updates and more.
          </p>
          <div className='input-areas'>
            <form>
              <input
                className='footer-input'
                name='email'
                type='email'
                placeholder='Your Email'
              />
              <FSButton buttonStyle='btn--primary' onClick={() => setModalShow(true)} >Subscribe</FSButton>
            </form>
          </div>
        </section>
      </div>
      <section class='social-media'>
        <small class='website-rights'>© 2024 fiStone, Inc., All rights reserved.</small>
      </section>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </div>
  );
}

export default Footer;